import {
  Typography,
  Button,
  CardContent,
  Divider,
  CardActions,
  Badge,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { UserContext } from "../../contexts/userContext";
import { NonmatchedService } from "../../services/nonmatchedService";
import ExpandMoreInfoCard from "./EncounterExpandMoreInfoCard";
import { prescriptionGen } from "../../utils/dictGen";
import Tags from "../Tags";
import Swal from "sweetalert2";
import { QueueContext } from "../../contexts/queueContext";
import LoadingBlock from "../LoadingBlock";
import { AuditService } from "../../services/auditService";
import { useNavigate } from "react-router-dom";

export default function CheckOutRecord({ c, index }) {
  const [recordInfo, setRecordInfo] = React.useState(null);
  const [checkedOut, setCheckedOut] = React.useState(false);
  const [owner, setOwner] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const userCtx = React.useContext(UserContext);
  const qCTX = React.useContext(QueueContext);
  const { queueMessages } = qCTX;
  const user_id = userCtx.user.user_id;
  const opt_out = userCtx.user.opt_out_checkout;
  const { user, saveUser } = userCtx;
  const navigate = useNavigate();
  const { userOptOut } = AuditService();
  const { checkInRecord, checkOutRecord } = NonmatchedService();
  const [isReAudit, setIsReAudit] = React.useState(false);

  React.useEffect(() => {
    if (!c) return;
    setCheckedOut(c.checkedOut);
    setIsReAudit(c.isReaudit);
    setOwner(c.owner);
  }, [queueMessages.NonMatchMessages]);

  // React.useEffect(() => {
  //   console.log("recordInfo updated:", recordInfo);
  // }, [recordInfo]);

  const handleCheckOut = () => {
    const processCheckout = () => {
      userCtx.saveFormUser();
      setIsLoading(true);
      checkOutRecord(
        c.rx_prx_id,
        (data) => {
          console.log("Checkout response:", data); // Debug log
          if (data && data.prescription) {
            const formattedData = prescriptionGen([data.prescription]);
            console.log("Formatted data:", formattedData); // Debug log
            setRecordInfo(formattedData[0]); // Note: prescriptionGen returns an array
          }
          setCheckedOut(true);
          setOwner(user_id);
          setIsLoading(false);
        },
        (err) => {
          console.error("Checkout error:", err); // Debug log
          if (err.status === 400) {
            Swal.fire({
              title: "Already Checked Out",
              text: "Sorry, this record is already checked out. Please select another.",
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
          setCheckedOut(true);
          setOwner(0);
          setIsLoading(false);
        }
      );
    };

    if (opt_out) {
      processCheckout();
    } else {
      Swal.fire({
        text: "Check out this record?",
        showCancelButton: true,
        confirmButtonColor: "#1976d2",
        confirmButtonText: "Confirm",
        cancelButtonColor: "#769FCD",
        color: "black",
        footer: `
          <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
          <label for="agree-checkbox" style="font-size:12px;">Don't show checkout messages again</label>
        `,
        preConfirm: () => {
          if (document.getElementById("agree-checkbox").checked) {
            userOptOut(
              true,
              "checkout",
              (data) => {},
              (err) => {
                console.log(err);
              }
            );
            const newUser = {
              ...user,
              userInfo: {
                ...user,
                opt_out_checkout: true,
              },
            };
            saveUser(newUser);
            return true;
          }
          return undefined;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          processCheckout();
        }
      });
    }
  };

  const handleCheckIn = () => {
    if (opt_out) {
      setIsLoading(true);
      checkInRecord(
        c.rx_prx_id,
        (data) => {
          setCheckedOut(false);
          setIsLoading(false);
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
        }
      );
    } else {
      Swal.fire({
        text: "Check in this record?",
        showCancelButton: true,
        confirmButtonColor: "#1976d2",
        confirmButtonText: "Confirm",
        cancelButtonColor: "#769FCD",
        color: "black",
        footer: `
        <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
        <label for="agree-checkbox" style="font-size:12px;">Don't show checkout messages again</label>
      `,
        preConfirm: () => {
          if (document.getElementById("agree-checkbox").checked) {
            userOptOut(
              true,
              "checkout",
              (data) => {},
              (err) => {
                console.log(err);
              }
            );
            const newUser = {
              ...user,
              userInfo: {
                ...user,
                opt_out_checkout: true,
              },
            };
            saveUser(newUser);
            return true;
          } else {
            return undefined;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          userCtx.saveFormUser();
          setIsLoading(true);
          checkInRecord(
            c.rx_prx_id,
            (data) => {
              setCheckedOut(false);
              setIsLoading(false);
            },
            (err) => {
              console.log(err);
              setIsLoading(false);
            }
          );
        }
      });
    }
  };
  if (isLoading) return <LoadingBlock />;
  return (
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography sx={{ display: "flex" }}>
        <Typography sx={{ align: "right", width: "100%", display: "flex" }}>
          {checkedOut && owner === user_id ? (
            <Stack direction={"row"}>
              <Button
                sx={{ align: "right" }}
                variant="contained"
                onClick={handleCheckIn}
              >
                <Typography gutterBottom variant="h5" sx={{ width: "100%" }}>
                  {recordInfo
                    ? `Rx: ${recordInfo.rxNumber} : ${recordInfo.fills} Fill(s)`
                    : "Loading..."}
                </Typography>
                Check In Record
              </Button>
            </Stack>
          ) : !checkedOut ? (
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Typography gutterBottom variant="h5" sx={{ width: "100%" }}>
                {`Rx: ${c.rxNumber} : ${c.fills} Fill(s)`}
              </Typography>
              <Button
                sx={{ align: "right" }}
                variant="contained"
                onClick={handleCheckOut}
              >
                Check Out Record
              </Button>
            </Stack>
          ) : (
            ""
          )}
          <Tags c={c} />
        </Typography>
      </Typography>

      <Typography align="left" color="black" fontFamily={"unset"}>
        {`Pharmacy: ${c.pharmacy}`}
      </Typography>
      <Typography align="left" color="black" fontFamily={"unset"}>
        {checkedOut && owner === user_id && recordInfo
          ? `Patient Name: ${recordInfo.patientName}`
          : "Patient Name: --"}
      </Typography>
      <Typography align="left" color="black" fontFamily={"unset"}>
        {checkedOut && owner === user_id && recordInfo
          ? `DOB: ${recordInfo.dob}`
          : "DOB: --"}
      </Typography>
      <Typography align="left" color="black" fontFamily={"unset"}>
        {checkedOut && owner === user_id && recordInfo
          ? `MRN: ${recordInfo.patientMRN}`
          : "MRN: --"}
      </Typography>
      <Typography align="left" color="black" fontFamily={"unset"}>
        {`Prescriber Name: ${c.prescriberName}`}
      </Typography>
      <Divider orientation="horizontal" flexItem sx={{ pb: 2 }} />

      {checkedOut && owner === user_id && recordInfo ? (
        <CardActions
          disableSpacing
          sx={{
            fontFamily: "unset",
            fontSize: "large",
            alignItems: "right",
            alignContent: "end",
          }}
        >
          <ExpandMoreInfoCard c={recordInfo} index={index} />
        </CardActions>
      ) : null}
    </CardContent>
  );
}
