import React, { useCallback } from "react";
import { NonmatchedService } from "../services/nonmatchedService";
import { QueueContext } from "./queueContext";
import { useRecoilState } from "recoil";
import { pageAtom } from "../atoms/atoms";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import { refreshToken } from "../services/loginService";

export const UserContext = React.createContext({
  loading: true,
  user: null,
  formUser: null,
  getUser: () => {
    return null;
  },
  saveUser: (user) => {},
  saveUserState: (user) => {},
  logout: () => {},
  saveFormUser: (user) => {},
  getFormUser: () => {
    return null;
  },
  saveFormUserState: () => {},
  getToken: () => {},
  getRoles: () => {},
  isSessionAlive: () => {},
  refreshUserToken: () => {},
});

const EXPIRY_DAYS = 2;
const SESSION_CHECK_INTERVAL = 60000;

export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [formUser, setFormUser] = React.useState(null);
  const qCtx = React.useContext(QueueContext);
  const [pages, setPages] = useRecoilState(pageAtom);
  const { checkInAllRecords } = NonmatchedService();

  const checkSessionExpiration = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      //  console.log("Token check 1");
      try {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        //   console.log(`Expiration: ${new Date(expirationTime).toLocaleString()}`);
        if (expirationTime < currentTime) {
          console.log("Token expired");
          Swal.fire({
            title: "Session Expired",
            text: "Your session has expired. Please log in again.",
            icon: "warning",
            confirmButtonText: "OK",
          }).then(() => {
            logout();
            window.location.href = "/LogOut";
          });
        }
      } catch (error) {
        // console.log("Token check error hit");
        console.error("Error checking session:", error);
        logout();
        window.location.href = "/LogOut";
      }
    }
  }, []);
  //in case it needs to be manually refreshed
  const refreshUserToken = async () => {
    try {
      const newToken = await refreshToken();
      if (newToken) {
        // Update the token in localStorage
        localStorage.setItem("token", newToken);
        return true;
      }
    } catch (error) {
      console.error("Error refreshing user token:", error);
      Swal.fire({
        title: "Session Expired",
        text: "Your session has expired. Please log in again.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then(() => {
        logout();
        window.location.href = "/LogOut";
      });
    }
    return false;
  };

  React.useEffect(() => {
    const foundUser = getUser();

    setLoading(false);
    if (foundUser) {
      saveUserState(foundUser);
    }
    const foundFormUser = getFormUser();
    if (foundFormUser) {
      saveFormUserState(foundFormUser);
    }
    getToken();

    const intervalId = setInterval(
      checkSessionExpiration,
      SESSION_CHECK_INTERVAL
    );

    return () => clearInterval(intervalId);
  }, []);

  const getUser = () => {
    let user = null;
    try {
      let userFromCookie = Cookies.get("prorx_user");
      user = userFromCookie && JSON.parse(userFromCookie);
    } catch (error) {
      console.log(
        "Something bad happened while trying to get the user from local storage."
      );
      console.log(error);
    }

    return user;
  };
  const getFormUser = () => {
    let formUser = null;
    try {
      // let formUserFromStorage = localStorage.getItem("formUser");
      // formUser = formUserFromStorage && JSON.parse(formUserFromStorage);
      let formUserFromCookie = Cookies.get("formUser");
      formUser = formUserFromCookie && JSON.parse(formUserFromCookie);
    } catch (error) {
      console.log(
        "Something bad happened while trying to get the user from local storage."
      );
      console.log(error);
    }

    return formUser;
  };

  const saveUser = (user) => {
    //localStorage.setItem("prorx_user", JSON.stringify(user));
    Cookies.set("prorx_user", JSON.stringify(user), { expires: EXPIRY_DAYS });
    setUser(user);
  };

  const isSessionAlive = () => {
    const token = localStorage.getItem("token");
    if (token === null || token === undefined || token == "undefined") {
      return false;
    }
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now();
      if (decodedToken.exp * 1000 > currentTime) {
        return true;
      }
    }
    return false;
  };
  const saveUserState = (user) => {
    setUser(user);
  };

  const logout = () => {
    let User = null;
    sessionStorage.clear();
    setPages({
      page: 1,
      numberOfPages: 1,
      filteredPage: 1,
      filteredNumberOfPages: 1,
    });
    try {
      // let UserFromStorage = localStorage.getItem("prorx_user");
      // User = UserFromStorage && JSON.parse(UserFromStorage);
      let UserFromCookie = Cookies.get("prorx_user");
      User = UserFromCookie && JSON.parse(UserFromCookie);
    } catch (error) {
      console.log(
        "Something bad happened while trying to get the user from local storage."
      );
      console.log(error);
    }
    checkInAllRecords(
      User.user_id,
      () => {
        console.log("done");
      },
      () => {
        console.log("error");
      }
    );
    qCtx.closeEverything();
    //localStorage.clear();
    Cookies.remove("prorx_user");
    localStorage.removeItem("token");
    setUser(null);
  };

  const saveFormUser = () => {
    // setFormUser(user.first_name + " " + user.last_name);
    // localStorage.setItem(
    //   "formUser",
    //   JSON.stringify(user.first_name + " " + user.last_name)
    // );
    const formUser = user.first_name + " " + user.last_name;
    setFormUser(formUser);
    Cookies.set("formUser", JSON.stringify(formUser), { expires: EXPIRY_DAYS });
  };

  const getToken = () => {
    if (localStorage.getItem("token") === null) {
      return null;
    }
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);

    return decodedToken;
  };

  const getRoles = () => {
    const token = getToken();
    const roles = token?.role;
    return roles;
  };

  const saveFormUserState = (foundFormUser) => {
    setFormUser(foundFormUser);
  };

  return (
    <UserContext.Provider
      value={{
        loading,
        user,
        saveUserState,
        getUser,
        saveUser,
        logout,
        saveFormUser,
        formUser,
        getFormUser,
        saveFormUserState,
        getToken,
        getRoles,
        isSessionAlive,
        checkSessionExpiration,
        refreshUserToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
